import React from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../Projects.json"; // Path to JSON file
import {useTranslation} from 'react-i18next';
const PreviousNextProjects = () => {
    const { nomeprogetto } = useParams<{ nomeprogetto: string }>();
    const currentIndex = projectsData.findIndex((proj) => proj.id === nomeprogetto);
    const {t}=useTranslation();
    // Handle edge cases to loop around
    const prevIndex = (currentIndex - 1 + projectsData.length) % projectsData.length;
    const nextIndex = (currentIndex + 1) % projectsData.length;

    return (
       <div className="flex flex-row justify-between py-[2rem] ">
           <div className="">
               <Link to={`/item/${projectsData[prevIndex].id}`} className="text-white">

                   <button
                       className="w-[10rem] md:w-auto md:h-10 h-[8rem]  text-center text-white py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                       style={{
                           background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                       }}
                   >  {t("Previous:")} {t(projectsData[prevIndex].title)} </button>
               </Link>

           </div>
           <div className="">
               <Link to={`/item/${projectsData[nextIndex].id}`} className="text-white">
                   <button
                       className="w-[10rem] md:w-auto md:h-10 h-[8rem] text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                       style={{
                           background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                       }}
                   >

                        {t("Next:")} {t(projectsData[nextIndex].title)} </button>  {/*!!!da rivedere l'implementazione di previous-next(non sono visibili) */}
               </Link>
           </div>
       </div>

    );
};

export default PreviousNextProjects;
