import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import ExhibitSlider from "../common/ExhibitSlider";
import SignageSlider from "../common/ExhibitSlider";
import PartnerGrid from "../common/PartnerGrid";
import SectionsHeader from "../components/SectionsHeader";
import { t } from "i18next";
import Helmetribes from "./helmetMetatags";
export default function OperationalLeasing() {

  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
        <Helmetribes title={t("OPERATIONAL LEASING")} description={t("Flexible Financing for Your Technology Needs")}url="https://www.ribesdigilab.it/operational-leasing"/>
      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1  ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-70 " >
            {/*<img src="/projects/MARTINI LEDWALL/CASA_MARTINI_0.jpg" alt="sara" className="object-cover w-full h-full" />*/}
            <img src="/projects/LEASING/LEASING.jpg" alt="sara" className="object-cover w-full h-full " />

          </div>


          <div className="flex flex-col">
            <SectionsHeader title={t("OPERATIONAL LEASING")} subtitle={t("Flexible Financing for Your Technology Needs")}/>

            <div className="absolute inset-0 h-screen w-auto">
              <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/operational-leasing/#what"  description1={t("what we do")} to2="/operational-leasing/#why" description2={t("why")} /></div>

            </div>
          </div>

        

        </div>
        <div id='what' className="h-1"></div>
        <div className="mx-12 mb-4 mt-40 h-full flex flex-col bg-opacity-50 z-40 pt-20">
          <div>
            <div className="grid  md:grid-rows-1 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                
                <ImageTextHome
                    text={t("WHAT IS OPERATIONAL LEASING")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                description={t("Operational leasing is a modern, flexible solution that allows businesses to access technology and IT equipment without the need for upfront investment. Instead of purchasing equipment outright, companies can lease it for a fixed term, paying in installments, while benefiting from maintenance, insurance, and continuous technological upgrades. At the end of the leasing period, there’s an option to renew the lease, return the equipment, or purchase it at a favorable rate.")} textAlign="left"
                    />
              </div>

              <div
                  className="value-item text-white flex flex-col justify-center w-full text-center text-3xl"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >

                <div id='why' className="h-10"></div>
                <h1 data-aos="fade-zoom-in"
                    data-aos-offset="100px"
                    data-aos-delay="0"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600"
                    className=" mb-12 mt-40 text-5xl leading-120 tracking-tighter text-center uppercase  text-white " >
                    {t("ADVANTAGES OF OPERATIONAL LEASING")}

                </h1>

                <div data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600"
                     data-aos-once="true">
                  <ProductDescription
                      title={t("Diluted Payments")}
                      description={t("Operational leasing allows businesses to spread the cost of technology investments over time, reducing the financial burden of large upfront payments.")}
                  /></div>
                <div data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600"
                     data-aos-once="true">
                  <ProductDescription
                      title={t("All Risk Insurance Coverage")}
                      description={t("Operational leasing includes comprehensive insurance coverage, protecting businesses from the risks of equipment damage, theft, or obsolescence.")}
                  /></div> <div data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600"
                     data-aos-once="true">
                  <ProductDescription
                      title={t("Tax Benefits")}
                      description={t("Leasing payments are considered operating expenses, making them tax-deductible and providing businesses with significant savings.")}
                  /></div><div data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600"
                     data-aos-once="true">
                  <ProductDescription
                      title={t("Continuous Technological Renewal")}
                      description={t("Operational leasing ensures that businesses always have access to the latest technology, with the option to upgrade equipment at the end of the lease term.")}
                  /></div><div data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600"
                     data-aos-once="true">
                  <ProductDescription
                      title={t("Buyout Option")}
                      description={t("At the end of the lease, businesses have the option to purchase the leased equipment at a reduced rate, giving flexibility in long-term asset management.")}
                  /></div>



              </div> <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("OUR SOLUTIONS")}
                    imageSrc="/projects/LEASING/image 6.png"
                    description={t("In partnership with GRENKE, a leader in commercial and financial solutions since 1978, we offer operational leasing as an attractive alternative to purchasing IT and technology equipment. This leasing option provides flexibility in planning investments, allowing businesses to upgrade their technological infrastructure without the burden of high initial costs. Whether you're looking to implement new systems, upgrade existing ones, or start a new project, operational leasing offers a secure and cost-effective way to achieve your goals.")} textAlign="left"
                    reverse={false}
                    
                />
              </div>

            </div>
          </div>
        </div>


        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
              {t("DO YOU WANT TO KNOW MORE?")}
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                      imageSrc="/icons/stylus_note (1).svg"
                      title={t("we can CUSTOMIZE THAT")}
                      description={t("We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization.")}
                      textAlign="center"
                  />
                </div>
              </div>

              <div className="">
                {/*bg-green-500*/}

                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/desktop_windows (1).svg"
                    title={t("we can UPDATE that")}
                    description={t("We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently")}
                    textAlign="center"
                  />
                </div>
              </div>



              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/volunteer_activism.svg"
                  title={t("we can assist you overtime")}
                  description={t("We build advanced, custom software solutions that deliver flawless\n interactive experiences tailored to your project needs.")}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>



        {/* VIDEO Section */}
        {/*<div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">


        <div>
          <div>

            <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
            >
              <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>*/}

        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 text-center uppercase font-medium text-white ">
              {t("LET'S TALK ABOUT IT!")}
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className="font-mono w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
              {t("CONTACT US")}
              </button>
            </div>
          </Link>
        </div>

      </div>
      {/* {isPopupOpen && fileType && (
          <EmailPopup fileType={fileType} onClose={handleClosePopup} />
      )}*/}
      {isPopupOpen && (
          <OverlayForm
              formUrl="https://webforms.pipedrive.com/f/6UR9Re8nR5VKrsxbJHNTBDLcsXv6xjIStMZYDU4iiOvEJdEo7cZwg4M1uAm9NAG7WX"
              onClose={handleClosePopup}
          />
      )}
    </div>
  );
}
