import "../global.css";
import SimpleSlider from "../common/Slider";
import SliderItemAbout from "../common/SliderItemAbout";
import TeamPerson from "../common/TeamPerson";
import CircleSlider from "../common/CircleSlider";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import GradientButtons from "../common/GradientButtons";
import ImageTextHome from "../common/ImageText_Home";
import PageHeader from "../components/PageHeader";
import ImageTextCareer from "../common/ImageTextCareer";
import SectionsHeader from "../components/SectionsHeader";
import BlackButtons from "../common/BlackButtons";
import {useTranslation} from 'react-i18next';
import Helmetribes from "./helmetMetatags";
export default function About() {
  const {t}=useTranslation();

  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);
  return (
    <div>
        <Helmetribes title={t("ABOUT US")} description={t("Your trusted supplier for digital projects")}url="https://www.ribesdigilab.it/about"/>
      <div className="relative flex flex-col h-full  mt-20">
        {/* BECOME A REVOLVER Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1  ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-40 opacity-70" >

 
            <video src="/LogoRibes_In_Out.mp4" className=" w-full h-full object-cover" autoPlay={true} loop={true} muted playsInline/>

          </div>

          <div className="flex flex-col">
            <SectionsHeader title={t("Your trusted supplier for digital projects")} subtitle={""}/>

            <div className="absolute inset-0 h-screen w-auto">
              <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/about/#our-values"  description1={t("OUR VALUE")} to2="/about/#mission" description2={t("WHAT WE STANDS FOR")} /></div>

            </div>
          </div>
        </div>
        <div  className=" mb-24 h-full flex flex-col  z-40">
          {/* Your content here */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mt-40 mb-12 text-6xl leading-120 tracking-tighter text-center uppercase  text-white z-20 ">
              {t("WHO WE ARE")}
          </h1>


          <div className="mb-8 text-white text-center flex items-center justify-center">
            <div className="font-mono w-1/2">{t("Since 1998, Ribes digilab  has been committed to deliver reliable and innovative IT and")}{<br></br>}
            {t("multimedia solutions, driven by their dedicated and skilled technical team. At Ribes, we")}<br></br>
              {t("prioritize meeting our clients ambitions with the highest standards of efficiency")}<br></br>
                {t("and quality. Based in Italy since the beginning, Ribes provides innovation MADE IN")}<br></br>
                  {t("ITALY.")} </div>
          </div>
        </div>

        {/*WHAT WE VALUE*/}
        <div  className=" mb-24 h-full flex flex-col  z-40" id="our-values">
          {/* Your content here */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mt-40 mb-12 text-6xl leading-120 tracking-tighter text-center uppercase  text-white z-20 ">
              {t("WHAT WE VALUE")}
          </h1>


          <div className="mb-8">
            <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 px-4 sm:grid-cols-1 gap-y-20 items-start ">

              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="400"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageTextCareer
                    text={t("HONESTY")}
                    imageSrc="/careers_icons/careers_transparency.svg"
                    description={t("We value directness, transparency, and concreteness. We appreciate a professional and serious demeanor in every interaction")}
                    textAlign="left"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="800"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600"
              >
                <ImageTextCareer
                    text={t("ACCOUNTABILITY")}
                    imageSrc="/careers_icons/careers_responsiveness.svg"
                    description={t("WE ARE NOT afraid of saying 'I messed up'. We want to identify obstacles as soon as possible, so that we can work around them fast. ")}
                    textAlign="left"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="1200"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600"
              >
                <ImageTextCareer
                    text={t("PROACTIVENESS")}
                    imageSrc="/careers_icons/careers_agility.svg"
                    description={t("IN A GROWING COMPANY, EACH PERSON MAKES A DIFFERENCE. WE RELY ON EACH OTHER'S PROACTIVE APPROACH TO KEEP IMPROVING. ")}
                    textAlign="left"
                />
              </div>
              <div className="value-item"
                   data-aos="fade-zoom-in"
                   data-aos-offset="300px"
                   data-aos-delay="1600"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600"
              >
                <ImageTextCareer
                    text={t("OBJECTIVE-DRIVEN")}
                    imageSrc="/careers_icons/careers_reilability.svg"
                    description={t("WE VALUE THE EFFECTS OF OUR WORK, NOT THE TIME SPENT SITTING AT THE DESK. WE EMPOWER EVERY TEAM MEMBER BASED ON THEIR UNIQUE SKILLSET.")}
                    textAlign="left"
                />
              </div>
            </div>
          </div>
        </div>

        {/*<div
            className=" absolute inset-0 flex items-center  z-0  2xl:opacity-90 lg:opacity-90 md:opacity-90 sm:opacity-90 ">
          <img
              className="object-cover h-auto w-screen"
              src="/10.jpg"
              alt="triangles"
          />
        </div>*/}

        {/* ABOUT US */}

        <div  className="flex justify-center pt-20 pb-10  h-full bg-black z-30 w-full">
          <h1  id="mission" className="opacity-0 animate-fadeIn delay-2 text-4xl md:text-6xl  text-white text-center uppercase">
            {t("what we stand for")}
          </h1>
        </div>
        <div className="mx-12 mb-24 h-full flex flex-col z-40 pt-10 ">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("OUR VISION")}
                    imageSrc="LogoRibes_In_Out - Copy.gif"
                    description={t("To be a pioneering leader in the digital and multimedia industry, driving business innovation through advanced technology solutions that blend creativity with efficiency. We envision a future where immersive digital experiences and cutting-edge multimedia tools redefine how people interact with technology, creating a more sustainable and productive world.")}
                    textAlign="left"
                />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("OUR MISSION")}
                    imageSrc="LogoRibes_In_Out - Copy.gif"
                    description={t("to empower businesses and institutions by integrating custom technologies that enhance engagement, optimize processes, and safeguard cultural heritage. Through our dedication to research and development, we ensure that every project reflects the highest standards of quality and efficiency, proudly representing Italian excellence in digital innovation.")}
                    textAlign="right"
                    reverse={true}
                />
              </div>
            </div>
          </div>
        </div>
        {/*TEAM PART*/}
        {/*<div className="px-10 pt-28">
          <h1 className="text-4xl md:text-6xl  text-white text-center py-4 uppercase">
            meet the team
          </h1>
          <div
            className="grid grid-cols-2 md:grid-cols-4 gap-5 items-start"
            data-aos="zoom-out-up"
            data-aos-offset="400px"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-delay="0"

          >
            <TeamPerson imageSrc="/team/davide.png" name="davide perinciolo" role="CEO" />
            <TeamPerson
              imageSrc="/team/roxana.png"
              name="roxana"
              role="role"
            />
            <TeamPerson
              imageSrc="/team/chiara.png"
              name="chiara mastino"
              role="role"
            />
            <TeamPerson
              imageSrc="/team/marco.png"
              name="marco massasso"
              role="role"
            />

            <TeamPerson
              imageSrc="/team/francesco_1080_medium.jpg"
              name="andrea lamberti"
              role="IT technician"
            />
            <TeamPerson
              imageSrc="/team/pavlos_1080_medium.jpg"
              name="nicola milano"
              role="mechanical engineer"
            />

          </div>
        </div>*/}

        {/*<div id="timeline" className="px-10 pt-40">
          <h1 className="text-4xl md:text-6xl text-white text-center py-16 uppercase">
            How We've (R)evolved
          </h1>

          <div  data-aos="zoom-in-out"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600">
          <CircleSlider></CircleSlider>

          </div>
        </div>*/}

        {/* TRUSTED BY */}
        <div className="flex flex-col  relative space-y-10 pt-20">


          <div className="flex flex-col  relative space-y-10 pt-20">
            <div className="flex items-center w-full ">
              <div className="grid grid-cols-3 text-white w-full mx-5">
                <div className="col-span-8 flex justify-center pb-16">
                  <h1 className="text-4xl md:text-6xl uppercase ">{t("our partners")}</h1>
                </div>
                <div className="col-span-1 flex flex-col items-center "  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-3 sm:h-7 md:h-10 w-auto"
                      src="/logo-solutions.png"
                      alt="ribes solutions"
                      style={{ filter: "brightness(0) invert(1)" }}
                    />
                  </div>
                </div>
                
                
                <div className="col-span-1 flex flex-col items-center "  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="400" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-3 sm:h-7 md:h-10 w-auto "
                      src="/logo-egizio.png"
                      alt="museo egizio"
                    />
                  </div>
                </div>
                
                <div className="col-span-1 flex flex-col items-center"  data-aos="fade-zoom-in" data-aos-offset="200" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-duration="600" data-aos-once="true">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      className="object-cover h-5 sm:h-7 md:h-10 w-auto"
                      src="/logo-robin.png"
                      alt="robin studio"
                    />
                  </div>
                </div>


              </div>
            </div>

            <div className="flex items-center justify-center pt-20">
            <Link to="/careers">
              <button
                className="font-mono w-auto h-10 text-center text-white  px-4 transform transition-transform duration-200 hover:scale-110"
                style={{
                  background:
                    "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                }}
              >
              {t("JOIN OUR TEAM")}
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
