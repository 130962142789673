import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { LANGUAGES } from "../constants";
import { t } from "i18next";
import i18n from '../i18n/i18n';

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
  children?: NavigationItem[];
}

const navigation: NavigationItem[] = [
  { name: "/home", href: "/home", current: true },
  {
    name: "/services",
    href: "/services",
    current: false,
    children: [
      { name: "HERITAGE 4.0", href: "/services/cultural-heritage", current: false },
      { name: "INDUSTRY 4.0", href: "services/industry-40", current: false },
      { name: "AUTOMOTIVE", href: "services/automotive", current: false },
      { name: "DIGITAL SIGNAGE", href: "/services/digital-signage", current: false },
      { name: "EXHIBIT installations", href: "/services/exhibitions-installations", current: false },
      { name: "networks", href: "/services/networks", current: false },
      { name: "IT support", href: "/services/it", current: false },
      { name: "operational leasing", href: "/operational-leasing", current: false },
    ],
  },
  { name: "/about us", href: "/about", current: false },
  { name: "/case history", href: "/case-history", current: false },
  { name: "/careers", href: "/careers", current: false },
  {
    name: "/trusted by",
    href: "/trusted-by",
    current: false,
    children: [
      { name: "our clients", href: "/clients", current: false },
      { name: "technical partners", href: "/partners", current: false },
    ],
  },
  { name: "/contact", href: "/contact", current: false },
  {
    name: "/Download",
    href: "/Download",
    current: false,
    children: [
      { name: "helpdesk portable", href: "https://get.teamviewer.com/6rbvez9", current: false },
      { name: "helpdesk installer", href: "https://get.teamviewer.com/64fn5bq", current: false },
    ],
  },
  
];

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const [currentItem, setCurrentItem] = useState<string>("HOME");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState<boolean>(false); // Stato per services
  const [isTrustedByDropdownOpen, setIsTrustedByDropdownOpen] = useState<boolean>(false); // Stato per trusted by
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState<boolean>(false); // Stato per Download
  const navigate = useNavigate();

  const handleMenuItemClick = (name: string) => {
    if (name === "/services") {
      setIsServicesDropdownOpen(!isServicesDropdownOpen);
      setIsTrustedByDropdownOpen(false);
      setIsDownloadDropdownOpen(false); // Chiude il dropdown di trusted by se aperto
    } else if (name === "/trusted by") {
      setIsTrustedByDropdownOpen(!isTrustedByDropdownOpen);
      setIsServicesDropdownOpen(false);
      setIsDownloadDropdownOpen(false); // Chiude il dropdown di services se aperto
    }else if (name === "/Download") {
      setIsDownloadDropdownOpen(!isDownloadDropdownOpen);
      setIsServicesDropdownOpen(false);
      setIsTrustedByDropdownOpen(false); // Chiude il dropdown di services se aperto
    } else {
      setIsServicesDropdownOpen(false);
      setIsTrustedByDropdownOpen(false);
      setIsDownloadDropdownOpen(false); // Chiude entrambi i dropdown quando si naviga altrove
      setCurrentItem(name);
      setIsMenuOpen(false); // Chiude il menu mobile
      let nav = navigation.find(item => item.name === name)?.href;
      if (nav !== null && nav !== undefined) navigate(nav);
    }
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (location.pathname.endsWith('/')) {
      location.pathname = location.pathname.slice(0, -1);
    }
    location.pathname === "/contact"
        ? setCurrentItem("/contact")
        : navigation.find(item => item.name === location.pathname) || navigation.find(item => item.children?.find(x => x.name === location.pathname))
            ? setCurrentItem(location.pathname)
            : console.log("no" + location.pathname);
  }, [location.pathname]);



  const changeToItalian = () => {
    i18n.changeLanguage("it"); // Cambia la lingua in italiano
};


const changeToEng = () => {
  i18n.changeLanguage("en"); // Cambia la lingua in italiano
};


  return (
      <div className=" font-mono bg-black fixed top-0 left-0 right-0 z-50">
        <div className="mx-auto max-w-full py-6 px-4 sm:px-4 lg:px-4">
          <div className="relative flex items-center justify-between gap-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 m-1 mx-6">
                <Link to="/home">
                  <img
                      className="h-14 w-auto max-w-xs"
                      src="/logobiancoribes.png"
                      alt="Ribes Digilab Logo"
                  />
                </Link>
              </div>
              
              
            </div>
            
            
            {/* bottone menu per small */}
            <div className="lg:hidden  ">
              <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="block text-white hover:text-gray-300 focus:text-gray-300 focus:outline-none"
              >
                {isMenuOpen ? (
                    <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                ) : (
                    <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 6h16M4 12h16m-7 6h7"
                      />
                    </svg>
                )}
              </button>
            </div>

            <div className="xl:gap-6 items-center hidden lg:flex lg:justify-between">
              {navigation.map((item: NavigationItem) => (
                  <div className="relative group px-2" key={item.name}>
                    {item.children ? (
                        <button
                            onClick={() => handleMenuItemClick(item.name)}
                            className={classNames(
                                item.name === "/Download"
                                    ? "hover:bg-black bg-gradient-to-r from-gradient-start to-gradient-end text-white px-2 py-2 text-xl uppercase transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                                    : currentItem.startsWith(item.name)
                                        ? "text-black uppercase hover:scale-110"
                                        : "hover:text-orange-500 uppercase hover:scale-110",
                                "text-white sm:text-xl leading-32 hover:scale-110 hover:text-orange-500"
                            )}
                            aria-current={item.current ? "page" : undefined}
                        >
                          {t(item.name.replace("/", ""))}
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className={`h-5 w-5 inline-block transform transition-transform duration-200 ${
                                  (item.name === "/services" && isServicesDropdownOpen) ||
                                  (item.name === "/trusted by" && isTrustedByDropdownOpen)||
                                  (item.name === "/Download" && isDownloadDropdownOpen)
                                      ? "rotate-180"
                                      : ""
                              }`}
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </button>
                    ) : (
                        <button
                            onClick={() => handleMenuItemClick(t(item.name))}
                            className={classNames(/*
                                item.name === "/contact"
                                    ? "hover:bg-black bg-gradient-to-r from-gradient-start to-gradient-end text-white px-2 py-2 text-xl uppercase transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                                    : currentItem === item.name ||*/
                                    (item.name === "/services" && currentItem.startsWith("/services")) ||
                                    (item.name === "/trusted by" && currentItem.startsWith("/trusted by"))
                                        ? "text-orange-500 uppercase hover:scale-110"
                                        : "hover:text-orange-500 uppercase hover:scale-110",
                                "text-white sm:text-xl leading-32 hover:scale-110"
                            )}
                            aria-current={item.current ? "page" : undefined}
                        >
                          {t(item.name.replace("/", ""))}
                        </button>
                    )}
                    {item.children && item.name === "/services" && isServicesDropdownOpen && (
                        <div className="absolute left-0 w-48 py-2 mt-1 bg-black text-white block">
                          {item.children.map((child: NavigationItem) => (
                              <Link
                                  to={child.href}
                                  key={child.name}
                                  onClick={() => handleMenuItemClick(child.name)}
                                  className={classNames(
                                      currentItem === child.name ? "block px-4 py-2 uppercase" : "hover:text-orange-500 block px-4 py-2 uppercase"
                                  )}
                              >
                                {t(child.name.replace("/products/", ""))}
                              </Link>
                          ))}
                        </div>
                    )}{item.children && item.name === "/Download" && isDownloadDropdownOpen && (
                      <div className="absolute left-0 w-48 py-2 mt-1 bg-black text-white block">
                        {item.children.map((child: NavigationItem) => (
                            <Link
                                to={child.href}
                                key={child.name}
                                onClick={() => handleMenuItemClick(child.name)}
                                className={classNames(
                                    currentItem === child.name ? "block px-4 py-2 uppercase" : "hover:text-orange-500 block px-4 py-2 uppercase"
                                )}
                            >
                              {t(child.name.replace("/products/", ""))}
                            </Link>
                        ))}

                        
                      </div>
                  )}
                    {item.children && item.name === "/trusted by" && isTrustedByDropdownOpen && (
                        <div className="absolute left-0 w-48 py-2 mt-1 bg-black text-white block">
                          {item.children.map((child: NavigationItem) => (
                              <Link
                                  to={child.href}
                                  key={child.name}
                                  onClick={() => handleMenuItemClick(child.name)}
                                  className={classNames(
                                      currentItem === child.name ? "block px-4 py-2 uppercase" : "hover:text-orange-500 block px-4 py-2 uppercase"
                                  )}
                              >
                                {t(child.name.replace("/products/", ""))}
                              </Link>
                          ))}

                          
                        </div>
                    )}
                    
                  </div>
                  
              ))}
              {i18n.language === "it" ? (
        <button
          onClick={() => i18n.changeLanguage("en") }
          className="m-3"
        >

<svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 60 45">
  {/* Background */}
  <rect width="60" height="45" fill="#012169" />
  
  {/* White Cross */}
  <rect x="24" width="12" height="45" fill="#FFFFFF" />
  <rect y="16.5" width="60" height="12" fill="#FFFFFF" />
  
  {/* Red Cross */}
  <rect x="26" width="8" height="45" fill="#C8102E" />
  <rect y="18.5" width="60" height="8" fill="#C8102E" />
  
  {/* Diagonal White */}
  <polygon points="0,0 60,45 55,45 0,5" fill="#FFFFFF" />
  <polygon points="60,0 0,45 5,45 60,5" fill="#FFFFFF" />
  <polygon points="0,45 60,0 55,0 0,40" fill="#FFFFFF" />
  <polygon points="60,45 0,0 5,0 60,40" fill="#FFFFFF" />
  
  {/* Diagonal Red */}
  <polygon points="0,0 60,45 57,45 0,3" fill="#C8102E" />
  <polygon points="60,0 0,45 3,45 60,3" fill="#C8102E" />
  <polygon points="0,45 60,0 57,0 0,42" fill="#C8102E" />
  <polygon points="60,45 0,0 3,0 60,42" fill="#C8102E" />
</svg>


          {/*bandiera scozzese che piace a davide qui sotto: */}
          {/* 
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 30" width="40" height="30">

  <rect width="40" height="30" fill="blue" />


  <polygon points="0,0 5,0 40,22.5 40,30 35,30 0,7.5" fill="white" />
  <polygon points="40,0 35,0 0,22.5 0,30 5,30 40,7.5" fill="white" />

  <rect x="17" width="6" height="30" fill="red" />
  <rect y="12" width="40" height="6" fill="red" />

</svg>*/}
        </button>
      ) : (
        <button
          onClick={() => i18n.changeLanguage("it")}
          className="m-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 2"
            width={40}
            height={30}
            className="rounded-md"
          >
            <rect width="1" height="2" x="0" fill="#009246" /> {/* Verde */}
            <rect width="1" height="2" x="1" fill="#ffffff" /> {/* Bianco */}
            <rect width="1" height="2" x="2" fill="#ce2b37" /> {/* Rosso */}
          </svg>
        </button>
      )}
            </div>
            
          </div>

          {/* Mobile Menu con il tuo hamburger */}
          <div className={`${isMenuOpen ? "block lg:hidden" : "hidden lg:hidden"}`}>
            <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-black shadow-lg">
              <div className="p-4">
                <nav className="flex flex-col gap-2 mt-4">
                  <div className="text-white inset-y-0 right-0 fixed pt-5 pb-6 pr-4">
                    <button
                        onClick={() => setIsMenuOpen(false)}
                        className="self-end"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-6 w-6"
                      >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="pt-12 flex flex-col">
                    {navigation.map((item) =>
                        item.children ? (
                            <Disclosure as="div" key={item.name} className="">
                              {({ open }) => (
                                  <>
                                    <Disclosure.Button className="flex uppercase pb-4 items-center justify-between w-full text-white hover:text-orange-500">
                                      {t(item.name.replace("/", ""))}{" "}
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className={`h-5 w-5 inline-block transform transition-transform duration-200 ${
                                              open ? "rotate-180" : ""
                                          }`}
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                      >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 9l-7 7-7-7"
                                        />
                                      </svg>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-5 pb-2 space-y-1 flex flex-col">
                                      {item.children?.map((child) => (
                                          <Link
                                              to={child.href}
                                              key={child.name}
                                              onClick={() => handleMenuItemClick(child.name)}
                                              className={classNames(
                                                  currentItem === child.name
                                                      ? "bg-lightBlue text-white uppercase  "
                                                      : "text-white hover:bg-lightBlue  hover:text-orange-500 uppercase"
                                              )}
                                          >
                                            {t(child.name.replace("/products/", ""))}{" "}
                                          </Link>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                              )}
                            </Disclosure>
                        ) : (
                            <Link
                                to={item.href}
                                key={item.name}
                                onClick={() => handleMenuItemClick(item.name)}
                                className={classNames(
                                    currentItem === t(item.name)
                                        ? "bg-lightBlue text-white uppercase  pb-4"
                                        : "text-white hover:bg-lightBlue hover:text-orange-500 uppercase pb-4"
                                )}
                            >
                              {t(item.name.replace("/", ""))}{" "}
                            </Link>
                        )
                    )}
                    
                  </div>
                  
                </nav>
              </div>
              {i18n.language === "it" ? (
        <button
          onClick={() => i18n.changeLanguage("en") }
          className="m-3"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 60 45">
  {/* Background */}
  <rect width="60" height="45" fill="#012169" />
  
  {/* White Cross */}
  <rect x="24" width="12" height="45" fill="#FFFFFF" />
  <rect y="16.5" width="60" height="12" fill="#FFFFFF" />
  
  {/* Red Cross */}
  <rect x="26" width="8" height="45" fill="#C8102E" />
  <rect y="18.5" width="60" height="8" fill="#C8102E" />
  
  {/* Diagonal White */}
  <polygon points="0,0 60,45 55,45 0,5" fill="#FFFFFF" />
  <polygon points="60,0 0,45 5,45 60,5" fill="#FFFFFF" />
  <polygon points="0,45 60,0 55,0 0,40" fill="#FFFFFF" />
  <polygon points="60,45 0,0 5,0 60,40" fill="#FFFFFF" />
  
  {/* Diagonal Red */}
  <polygon points="0,0 60,45 57,45 0,3" fill="#C8102E" />
  <polygon points="60,0 0,45 3,45 60,3" fill="#C8102E" />
  <polygon points="0,45 60,0 57,0 0,42" fill="#C8102E" />
  <polygon points="60,45 0,0 3,0 60,42" fill="#C8102E" />
</svg>
        </button>
      ) : (
        <button
          onClick={() => i18n.changeLanguage("it")}
          className="m-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3 2"
            width={40}
            height={30}
            className="rounded-md"
          >
            <rect width="1" height="2" x="0" fill="#009246" /> {/* Verde */}
            <rect width="1" height="2" x="1" fill="#ffffff" /> {/* Bianco */}
            <rect width="1" height="2" x="2" fill="#ce2b37" /> {/* Rosso */}
          </svg>
        </button>
      )}
            </div>
          </div>
          
        </div>
        
      </div>
  );
}
