import React, {useState, useTransition} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import "./global.css";
import { SpeedInsights } from "@vercel/speed-insights/react";
import NavBar from "./common/NavBar";
import Home from "./view/Home";
import About from "./view/About";
import Career from "./view/Career";
import Contact from "./view/Contact";
import Configuratore from "./view/Configuratore";
import Footer from "./common/Footer";
import AOS from "aos";
import ReactGA from 'react-ga4';
import { initGA, logPageView } from './types/ga'; // Adjust the import path accordingly
import GAListener from './components/GAListener'; // Adjust the import path accordingly

import {useTranslation} from 'react-i18next'
import i18n from './i18n/i18n';

import { Analytics } from "@vercel/analytics/react";

import { useEffect } from "react";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heritage40 from "./view/Heritage40";
import DigitalSignage from "./view/DigitalSignage";
import ScrollToAnchor from "./ScrollToAnchor";
import NewsPage from "./view/NewsPage";
import NewsDetail from "./view/NewsDetail";
import Configuratore_New from "./view/Configuratore_New";
import ConfiguratoreUI from "./common/configuratore/ConfiguratoreUI";
import Clients from "./view/Clients";
import Partners from "./view/Partners";
import ExihibitInstallations from "./view/ExihibitInstallations";
import Networks from "./view/Networks";
import IT from "./view/IT";
import OperationalLeasing from "./view/OperationalLeasing";
import CustomCircle from "./common/CustomCircle";
import Industry40 from "./view/Industry40";
import Automotive from "./view/Automotive";
import HomeTemp from "./view/Home_Temporary";
import ProjectPage from "./view/ProjectPage";
import CaseHistoryPage from "./view/CaseHistoryPage";
import {isDesktop} from "react-device-detect";
import { it } from "node:test";
import { HelmetProvider } from 'react-helmet-async';
function Background() {
  const location = useLocation();

  return location.pathname !== "/news" &&
    !location.pathname.startsWith("/news/") && location.pathname !== "/services/sara" && !location.pathname.startsWith("/services/sara") /*&&
    location.pathname !== "/configuratore"*/ ? (
    <div className="h-[92rem] absolute top-0 left-0 w-full b  pointer-events-none mt-20 "></div>
  ) : (
    <div></div>
  );
}
function App() {
  AOS.init();
  const {t}=useTranslation();
  
  
  useEffect(() => {
    // Imposta manualmente la lingua per il debug
     // Cambia 'it' con 'en' per l'inglese

    // Effettua eventuali altre operazioni iniziali
  }, []);
  



  useEffect(() => {
    initGA(process.env.ANALYTIC); // Replace with your actual GA tracking ID
    
  }, []);

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description:
                  "We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, for other purposes as specified in the cookie policy. Use the “Accept” button to consent. Use the “Reject” button or close this notice to continue without accepting.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage Individual preferences",
            },
            preferencesModal: {
              title: "Manage cookie preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Accept current selection",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "Somebody said ... cookies?",
                  description: "I want one!",
                },
                {
                  title: "Strictly Necessary cookies",
                  description:
                      "These cookies are essential for the proper functioning of the website and cannot be disabled.",

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                      "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
                {
                  title: "More information",
                  description:
                      'For any queries in relation to my policy on cookies and your choices, please <a href="/contact">contact us</a>',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return (
      <div className="bg-black overflow-x-hidden ">
        <HelmetProvider>
        <BrowserRouter>
          <CustomCircle/>
          <NavBar/>
          <div className="bg-black">
            <Background/>
            <ScrollToAnchor/>
            <GAListener>
              <Routes>

                <Route path="/" element={<Navigate to={ "/home"}/>}/>
                <Route path="home" element={<HomeTemp/> }/>
                <Route path="about" element={<About/>}/>
                <Route path="case-history" element={<CaseHistoryPage/>}/>
                <Route path="/item/:nomeprogetto" element={<ProjectPage/>}/>
                <Route path="services/cultural-heritage" element={<Heritage40/>}/>
                <Route path="services/industry-40" element={<Industry40/>}/>
                <Route path="services/automotive" element={<Automotive/>}/>
                <Route path="/services/exhibitions-installations" element={<ExihibitInstallations/>}/>
                <Route path="/services/digital-signage" element={<DigitalSignage/>}/>
                <Route path="/services/networks" element={<Networks/>}/>
                <Route path="/services/it" element={<IT/>}/>
                <Route path="/operational-leasing" element={<OperationalLeasing/>}/>
                <Route path="careers" element={<Career/>}/>
                <Route path="news/:name/:id" element={<NewsDetail/>}/>
                <Route path="news" element={<NewsPage/>}/>
                <Route path="clients" element={<Clients/>}/>
                <Route path="partners" element={<Partners/>}/>
                <Route path="contact" element={<Contact/>}/>

                {/*<Route path="configuratore" element={<Configuratore />} />*/}
              </Routes>
            </GAListener>
          </div>
          <Footer/>
        </BrowserRouter>
        <Analytics/>
        <SpeedInsights/>
        </HelmetProvider>
      </div>
  );
}
export default App;
