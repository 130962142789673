import "../global.css";
import React, { useEffect } from "react";
import {Link} from "react-router-dom";
import PipedriveForm from "../components/PipedriveForm";
import ProductWebForm from "../components/PipedriveForm";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ClientGrid from "../common/ClientGrid";
import PartnerGrid from "../common/PartnerGrid";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import SectionsHeader from "../components/SectionsHeader";
import { t } from "i18next";
import Helmetribes from "./helmetMetatags";

export default function Clients() {
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
      }, []);


    return (
        <div>
          
          <Helmetribes title={t("OUR CLIENTS")} description="see our clients"url="https://www.ribesdigilab.it/clients"/>

            {/* CONTACTS Section */}
            <div className="h-full flex flex-col bg-black  bg-opacity-50 z-50 mb-40 pt-20">

                <div>

                    <ClientGrid/>
                </div>
            </div>

            {/* advantages of working with us*/}

            



            <div  id='form-contacts' className="pb-20" ></div>

            {/*FORM SECTION*/}
             <div className="max-w-md mx-auto mt-10 p-6 "  >

                 <h1 className="text-4xl md:text-6xl uppercase z-40 text-white opacity-0 animate-fadeIn delay-2 ">{t("do you need help? let's talk about it!")}</h1>
                 <div data-aos="fade-zoom-in"
                      data-aos-offset="300px"
                      data-aos-delay="0"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600">
                     <ProductWebForm
                         formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                         portalId="145047450"
                         region="eu1"
                     />
                 </div>

            </div>





        </div>
    );
}
