import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import ExhibitSlider from "../common/ExhibitSlider";
import SectionsHeader from "../components/SectionsHeader";
import {useTranslation} from 'react-i18next'
import Helmetribes from "./helmetMetatags";
export default function ExihibitInstallations() {
  const {t}=useTranslation();
  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
        <Helmetribes title={t("EXHIBIT INSTALLATIONS")} description={t("Seamless Solutions for Museums and Beyond")}url="https://www.ribesdigilab.it/services/exhibitions-installations"/>
      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1  ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-80" >
            {/*<img src="/projects/MARTINI LEDWALL/CASA_MARTINI_0.jpg" alt="sara" className="object-cover w-full h-full" />*/}
            <img src="/projects/MUSEO TORINO PM/venaria.jpg" alt="sara" className="object-cover w-full h-full " />
          </div>


        <div className="flex flex-col">
          <SectionsHeader title={t("EXHIBIT INSTALLATIONS")} subtitle={t("Seamless Solutions for Museums and Beyond")}/>

          <div className="absolute inset-0 h-screen w-auto">
            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/services/exhibitions-installations/#infos"  description1={t("what we do")} to2="/case-history" description2={t("CASE HISTORY")} /></div>
            <div id="infos"/>
          </div>
        </div>
      </div>
        <div  className="mx-12 mb-24 h-full flex flex-col bg-opacity-50 z-40 mt-20 pt-20">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("WHAT WE DO")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description={t("We provide comprehensive exhibition installation services, both for museums and other cultural or commercial spaces. From designing and setting up advanced control systems to integrating multimedia hardware and ensuring top-tier security, we create immersive, interactive experiences that captivate audiences. Our solutions are tailored to meet the specific needs of each exhibit, ensuring the preservation and enhancement of cultural heritage or brand storytelling.")}                    textAlign="left"
                />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("HOW WE DO IT")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description={t("Our technical team manages every aspect of the installation process: from planning and design to implementation and maintenance. We work closely with clients to understand their vision and requirements, ensuring that our solutions align with their goals. By combining cutting-edge technology with expert craftsmanship, we deliver seamless, high-quality installations that engage and inspire visitors. Our commitment to excellence and innovation sets us apart in the field of exhibit installations.")}
                    textAlign="right"
                    reverse={true}
                />
              </div>
            </div>
          </div>
        </div>



        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/projects/RIYADH/img_3.png"
              className="w-auto md:w-5/12  h-auto "
                 alt="sara"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24  font-bold  "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >



            </div>
          </div>

          <div className="py-20 "></div>
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mb-12 text-5xl leading-120 tracking-tighter text-center uppercase  text-white " >
              {t("WHAT WE CAN DO")}{" "}

          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"

               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Custom Control Units")}
                description={t("We design and install custom control units tailored to each exhibition's needs. These units centralize the management of exhibit functions, such as lighting, interactive displays, environmental controls, and more. Each control unit is configured to operate with precision, allowing for independent or synchronized control of different exhibit elements, ensuring smooth operations and easy adjustments for museum staff.")}
            />        </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Electrical Systems Design")}
                description={t("Our team of experts handles the design and implementation of electrical systems for exhibitions, ensuring the safe and efficient distribution of power to all parts of the exhibit. We assess the specific power requirements for all equipment, from multimedia displays to specialized lighting, and install the appropriate infrastructure. Our focus on electrical safety guarantees that every installation meets rigorous standards, preventing overloads and ensuring uninterrupted operation.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Advanced Security Systems")}
                description={t("To safeguard valuable cultural artifacts and ensure the security of exhibition spaces, we integrate advanced security systems. These include motion detectors, alarm systems, access controls, and other technologies that protect against theft, vandalism, or unauthorized access. Our systems are designed to provide real-time monitoring and instant alerts, giving museum staff peace of mind while maintaining a secure environment for visitors.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Environmental Monitoring Sensors")}
                description={t("We install state-of-the-art environmental monitoring sensors that track temperature, humidity, air quality, and light exposure levels in exhibition spaces. These systems are crucial for preserving sensitive artifacts that could be damaged by fluctuating environmental conditions. Our sensors provide real-time data to ensure that the exhibition environment is maintained within optimal preservation parameters, extending the lifespan of priceless objects.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Video Surveillance Systems")}
                description={t("We set up comprehensive video surveillance systems that allow for 24/7 monitoring of exhibit areas. Using high-definition cameras and real-time streaming capabilities, our systems offer museum staff the ability to remotely monitor all parts of the exhibition space. The recorded footage can be stored for future review, and motion-activated cameras ensure that any suspicious activity is captured immediately, enhancing the security of the exhibit.")}
            />
          </div> <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Network Infrastructure")}
                description={t("Our team designs and installs network infrastructure to ensure that all exhibit components are interconnected and function cohesively. This includes setting up secure wired and wireless networks for multimedia displays, interactive installations, and control systems. Reliable network infrastructure is essential for integrating technologies like augmented reality, virtual reality, and interactive touchscreens, ensuring that these tools perform seamlessly throughout the exhibit.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Hardware Setup and Integration")}
                description={t("We handle the complete setup and configuration of all multimedia and interactive hardware. This includes installing projectors, touchscreens, interactive displays, VR/AR stations, and more. Our team ensures that each piece of equipment is correctly calibrated and fully functional, providing visitors with an immersive and interactive experience. Whether it's a single installation or an entire gallery of exhibits, we deliver high-quality, consistent results.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Ongoing Maintenance and Support")}
                description={t("We provide ongoing maintenance and support through tailored service contracts. Our maintenance services cover routine inspections, troubleshooting, and repairs to ensure that all systems continue to function at their best. We offer flexible maintenance plans based on the specific needs of each exhibition, ensuring long-term reliability and minimizing downtime. Whether you need regular check-ups or immediate technical assistance, our team is always available to support your exhibit’s success.")}
            />
          </div>


        </div>

        {/* PRODUCT GALLERY Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 " id="gallery">
          {/*  */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mt-20 mb-40  text-5xl leading-120 tracking-tighter text-center uppercase text-white ">
              {t("PRODUCT GALLERY")}{" "}
          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="300px"
               data-aos-delay="300"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <ExhibitSlider />
          </div>


        </div>

        {/*ANIMATED BLOCKS*/}

        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={1} end={26} unit={t("y")} />
            <Counter type="letter" start="" end="TECH" />
            <Counter type="watt" start={100} end={1000} unit="" />
          </div>
        </div>

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
              {t("DO YOU HAVE AN IDEA?")}
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">
              <div className="">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/stylus_note (1).svg"
                    title={t("we can design that")}
                    description={t("We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently")}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/desktop_windows (1).svg"
                    title={t("we can install that")}
                    description={t("We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization.")}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/volunteer_activism.svg"
                  title={t("we can assist you overtime")}
                  description={t("We build advanced, custom software solutions and we take care of maintenance overtime.")}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>





        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 text-center uppercase font-medium text-white ">
              {t("LET'S TALK ABOUT IT!")}
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className="font-mono w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
              {t("CONTACT US")}
              </button>
            </div>
          </Link>
        </div>

      </div>


    </div>
  );
}
