import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import projectsData from '../Projects.json'; // JSON file path
import '../global.css'; // Include animations from Heritage40.tsx
import {useTranslation} from 'react-i18next';
import Helmetribes from './helmetMetatags';
const CaseHistoryPage = () => {
    const {t}=useTranslation();

    const [filter, setFilter] = useState(''); // For filtering
    const [sortOrder, setSortOrder] = useState('most-recent'); // Sorting state
    const [filteredProjects, setFilteredProjects] = useState(projectsData); // Filtered projects
    const [categories, setCategories] = useState<string[]>([]); // Categories array
    const navigate = useNavigate();

    // Extract categories from the projects data
    useEffect(() => {
        const extractedCategories = Array.from(new Set(projectsData.map(project => project.category)));
        setCategories(extractedCategories);
    }, []);

    // Sorting Logic
    const sortProjects = ({projects}: { projects: any }) => {
        let sortedProjects = [...projects];
        switch (sortOrder) {
            case 'client':
                sortedProjects.sort((a, b) => a.client.name.localeCompare(b.client.name));
                break;
            case 'project':
                sortedProjects.sort((a, b) => a.title.localeCompare(b.title));
                break;
            case 'most-recent':
                sortedProjects.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                break;
            case 'least-recent':
                sortedProjects.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
                break;
            default:
                break;
        }
        return sortedProjects;
    };

    // Handle Card Click
    const handleCardClick = ({projectId}: { projectId: any }) => {
        navigate(`/item/${projectId}`);
    };

    // Handle Filter by Category
    const handleFilterByCategory = (category: string) => {
        if (category === '') {
            setFilteredProjects(projectsData); // Show all projects
        } else {
            setFilteredProjects(projectsData.filter(project => project.category === category));
        }
        setFilter(category);
    };

    return (
        <div className="container mx-auto p-4 text-white my-80">
            {/* Header with Categories */}
            <Helmetribes title={t("CASE HISTORY")} description="Discover our projects"url="https://www.ribesdigilab.it/case-history"/>
            <div className="flex justify-between items-center mb-6 my-10">
                <h1 className="text-4xl text-white">{t("CASE HISTORY")}</h1>
                <select
                    className="font-mono p-2 border-white rounded-none border-2 bg-black text-white"
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                >
                    <option value="most-recent">{t("Most Recent")}</option>
                    <option value="least-recent">{t("Least Recent")}</option>
                    <option value="client">{t("Client Name")}</option>
                    <option value="project">{t("Project Name")}</option>
                </select>
            </div>

            {/* Category Filter Buttons */}
            <div className="font-mono flex flex-wrap md:flex-row gap-4 mb-8 md:justify-start grid-cols-2 md:grid-cols-none">
                <button
                    className={`px-4 py-2 border-white border-2  ${filter === '' ? 'bg-white text-black' : 'bg-black'}`}
                    onClick={() => handleFilterByCategory('')}
                >
                    {t("All")}
                </button>
                {categories.map((category) => (
                    <button
                        key={category}
                        className={`px-4 py-2 border-white border-2  ${filter === category ? 'bg-white text-black' : 'bg-black'}`}
                        onClick={() => handleFilterByCategory(category)}
                    >
                        {t(category)}
                    </button>
                ))}
            </div>

            {/* Cards Grid */}
            <div className="font-mono grid grid-cols-1 md:grid-cols-2 gap-6">
                {sortProjects({projects: filteredProjects}).map((project) => (
                    <div
                        key={project.id}
                        className="cursor-pointer transition-transform transform hover:scale-105 bg-black border-2 border-white shadow-lg p-4 hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                        onClick={() => handleCardClick({projectId: project.id})}
                    >
                        <img
                            src={project.gallery[0]} // Show the first image of the gallery
                            alt={project.title}
                            className="w-full h-52 object-cover object-center"
                        />
                        <h2 className="text-xl  mt-4">{t(project.title)}</h2>
                        <p className="text-gray-600">{t(project.client.name)}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CaseHistoryPage;
