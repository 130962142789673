import { Helmet } from 'react-helmet-async';
import React from 'react';

interface HelmetMetatagsProps {
  title: string;
  description: string;
  keywords?: string;
  url?:string
}

//const DEFAULT_IMAGE_CARD = "/public/IMAGES/LogoRibes_big.png";
const DEFAULT_IMAGE_CARD ="/R_ribes.png"
 
const Helmetribes: React.FC<HelmetMetatagsProps> = ({ title, description, keywords,url = "" }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="keywords" content={`ribes, ribesdigilab, ribes digilab, heritage4.0, heritage, informatica, videosorveglianza, videosurveillance, hardware, installazioni museali, software, servizi IT, museums, impianti, IT support, helpdesk, development, UI interfaces, interfacce, networks, automotive, reti, server, consulenza, counseling, exhibit, ${keywords}`} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={DEFAULT_IMAGE_CARD} />
 
      {/*<meta property="og:image:alt" content="RIBESdigilab" /> 
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />*/}
  
      <meta property="og:image:alt" content="RIBESdigilab" /> 
      <meta property="og:image:type" content="image/png" /> 
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Welcome to Ribes Digilab."
      />
      <meta name="robots" content="index, follow" />
      
     
    </Helmet>
  );
};

export default Helmetribes;