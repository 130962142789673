import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import projectsData from "../Projects.json"; // Percorso al file JSON
import PreviousNextProjects  from "../../src/components/PreviousNextProjects";
import ProductWebForm from "../components/PipedriveForm";
import {useTranslation} from 'react-i18next';
// Interfaccia per il progetto
interface Project {
    id: string;
    title: string;
    subtitle: string;
    description: string;
    client: {
        name: string;
        logo: string;
    };
    date: string;
    category: string;
    services: string[];
    externalLinks: { name: string; url: string }[];
    gallery: string[];
    layout: number;
    relatedProjects: string[];
}

const ProjectPage: React.FC = () => {
    const {t}=useTranslation();

    const { nomeprogetto } = useParams<{ nomeprogetto: string }>();
    const [project, setProject] = useState<Project | null>(null);

    useEffect(() => {
        const projectData = projectsData.find((proj: Project) => proj.id === nomeprogetto);
        setProject(projectData || null);
    }, [nomeprogetto]);

    if (!project) return <div>Loading...</div>;

    switch (project.layout) {
        case 1:
            return <div className="z-50"><Layout1 project={project} t={t}/></div>;
        case 2:
            return <Layout2 project={project} t={t} />;
        case 3:
            return <Layout3 project={project} t={t} />;
        default:
            return <Layout1 project={project} t={t} />;
    }
};



const Layout1: React.FC<{ project: Project; t:Function }> = ({ project, t }) => (
 
    <div className="container mx-auto py-16 px-8 my-40 opacity-0 animate-fadeIn delay-1">
        <div className="text-center mb-12" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="0" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <h1 className="text-4xl md:text-6xl  text-white">{t(project.title)}</h1>
            <h2 className="text-2xl text-gray-300">{t(project.subtitle)}</h2>
        </div>
        <div className="mb-8" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="400" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <img src={project.client.logo} alt={`${project.client.name} logo`} className="sm:mx-4 lg:mx-auto lg:h-24" style={{ filter: "brightness(0) invert(1)" }} />
            <p className="font-mono text-center text-gray-500 py-4">{project.client.name} | {project.date}</p>
        </div>
        <p className="font-mono text-lg text-gray-300 mb-8 text-center" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600">
            {t(project.description)}
        </p>
        <div className="gallery-slider mb-16 flex flex-col items-center" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="800" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <Gallery images={project.gallery} />
        </div>
        <div className="font-mono justify-center items-center text-white h-screen">
            <PreviousNextProjects />
        </div>

        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
            <h1
                id='positions'
                className="opacity-0 animate-fadeIn delay-2  text-5xl leading-120 text-center uppercase font-medium text-white ">
                {t("LET'S TALK ABOUT IT!")}
            </h1>
            <ProductWebForm
                formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                portalId="145047450"
                region="eu1"
            />
        </div>
    </div>
);


const Layout2: React.FC<{ project: Project; t:Function}> = ({ project, t }) => (
    <div className="container mx-auto py-16 px-8 my-40">
        <div className="text-center mb-12" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="0" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <h1 className="text-4xl md:text-6xl  text-white">{t(project.title)}</h1>
            <h2 className="text-2xl text-gray-300">{t(project.subtitle)}</h2>
        </div>
        <div className="font-mono flex justify-center mx-4 mb-8" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="400" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <img src={project.client.logo} alt={`${project.client.name} logo`} className="h-24" style={{ filter: "brightness(0) invert(1)" }} />
            <div className="ml-4">
                <p className="text-lg text-gray-300">{project.client.name}</p>
                <p className="text-gray-500">{project.date}</p>
            </div>
        </div>
        <p className="font-mono text-lg text-gray-300 mb-12" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600">{t(project.description)}</p>
        <div className="text-center mb-12" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="800" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <h3 className="text-3xl text-white mb-4">Servizi Offerti</h3>
            <ul className="font-mono text-gray-300">
                {project.services.map((service, index) => (
                    <li key={index} className="text-lg">{t(service)}</li>
                ))}
            </ul>
        </div>
        <div className="font-mono justify-center items-center text-white h-screen" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="60" data-aos-easing="ease-in-sine" data-aos-duration="60">
            <PreviousNextProjects />
        </div>

        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
            <h1
                id='positions'
                className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                {t("LET'S TALK ABOUT IT!")}
            </h1>
            <ProductWebForm
                formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                portalId="145047450"
                region="eu1"
            />
        </div>
    </div>
);


const Layout3: React.FC<{ project: Project; t:Function }> = ({ project, t }) => (
    <div className="container mx-auto py-16 px-8 my-40">
        <div className="text-center mb-12" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="0" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <h1 className="text-4xl md:text-6xl text-white">{t(project.title)}</h1>
            <h2 className="text-2xl text-gray-300">{t(project.subtitle)}</h2>
        </div>
        <div className="grid grid-cols-1 space-y-0.5xl:grid-cols-2 gap-8 " data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="400" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <div>
                <img src={project.client.logo} alt={`${project.client.name} logo`} className="mx-auto h-24 mb-8" style={{ filter: "brightness(0) invert(1)" }} />
                <p className="font-mono text-lg text-gray-300">{t(project.description)}</p>
            </div>
            <div className="gallery-slider m10 mb-16 flex flex-col items-center">
                <Gallery images={project.gallery} />
            </div>
        </div>
        <div className="mt-16" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="600" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <h3 className="text-3xl text-white mb-4 text-center">Servizi Offerti</h3>
            <ul className="font-mono text-center text-gray-300">
                {project.services.map((service, index) => (
                    <li key={index} className="text-lg">{t(service)}</li>
                ))}
            </ul>
        </div>
        <div className="font-mono justify-center items-center text-white h-screen" data-aos="fade-zoom-in" data-aos-offset="300px" data-aos-delay="800" data-aos-easing="ease-in-sine" data-aos-duration="600">
            <PreviousNextProjects />
        </div>

        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
            <h1
                id='positions'
                className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120  text-center uppercase font-medium text-white ">
                {t("LET'S TALK ABOUT IT!")}
            </h1>
            <ProductWebForm
                formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                portalId="145047450"
                region="eu1"
            />
        </div>
    </div>
);




// Galleria immagini con slider
const Gallery = ({ images }: { images: string[] }) => {
    return (
        <div className="gallery-slider w-1/2 space-y-20">
            {images.map((src, index) => (
                <img key={index} src={src} alt={`Image ${index + 1}`} />
            ))}
        </div>
    );
};

// Previous/Next e progetti correlati



export default ProjectPage;
