import React from "react";
import { t } from "i18next";
interface ClientLogo {
    id: number;
    imageUrl: string;
    altText: string;
}

const clientLogos: ClientLogo[] = [
    { id: 1, imageUrl: "/clients/logo-iveco.png", altText: "Iveco" },
    { id: 2, imageUrl: "/clients/logo-cnh.png", altText: "CNH Industrial" },
    { id: 73, imageUrl: "/clients/logo-ftp.png", altText: "F P T" },
    
    { id: 55, imageUrl: "/clients/logo-magirus.png", altText: "MAGIRUS" },
    { id: 3, imageUrl: "/clients/logo-comau.png", altText: "CRF" },
    { id: 4, imageUrl: "/clients/logo-ferrero.png", altText: "Ferrero" },
    { id: 5, imageUrl: "/clients/logo-stellantis.png", altText: "Stellantis" },
    { id: 6, imageUrl: "/clients/logo-lavazza.png", altText: "Lavazza" },
    { id: 7, imageUrl: "/clients/logo-egizio.png", altText: "Museo Egizio" },
    { id: 8, imageUrl: "/clients/logo-maserati.png", altText: "Maserati" },
    { id: 9, imageUrl: "/clients/logo-Museo-Montagna 2.png", altText: "Museo Nazionale della montagna" },
    { id: 10, imageUrl: "/clients/logo-musei-reali-torino.png", altText: "Musei Reali Torino" },
    { id: 11, imageUrl: "/clients/logo-artissima.png", altText: "Artissima" },
    { id: 12, imageUrl: "/clients/logo-mauto.png", altText: "MAUTO" },
    
    
    
    { id: 13, imageUrl: "/clients/logo-ftm.png", altText: "Fondazione torino musei" },
    { id: 14, imageUrl: "/clients/logo-palazzomadama.png", altText: "palazzo madama" },
    { id: 15, imageUrl: "/clients/logo-gam-1.png", altText: "GAM Torino" },
    { id: 16, imageUrl: "/clients/logo-mao2.png", altText: "MUSEO DELL ARTE ORIENTALE" },
    { id: 17, imageUrl: "/clients/logo-juventus.png", altText: "Juventus" },

    
    
    { id: 18, imageUrl: "/clients/logo-maison-musee.png", altText: "Maison Musée Berton" },
    { id: 19, imageUrl: "/clients/logo-museo-cinema.png", altText: "Museo Nazionale del cinema Torino" },
    { id: 20, imageUrl: "/clients/logo-palazzo-mazzetti.png", altText: "Palazzo Mazzetti" },
    { id: 21, imageUrl: "/clients/logo-pinacoteca-albertina.png", altText: "Pinacoteca Albertina di Torino" },
    { id: 22, imageUrl: "/clients/logo-accademia-albertina.png", altText: "Accademia Albertina di Belle Arti di Torino" },
    { id: 29, imageUrl: "/clients/logo-opificio.png", altText: "Opificio delle Pietre Dure SAFS" },
    { id: 23, imageUrl: "/clients/logo-citec.png", altText: "CITEC" },
    { id: 24, imageUrl: "/clients/logo-federlegnoarredo.png", altText: "Federlegnoarredo (FLA)" },
    
    { id: 26, imageUrl: "/clients/logo-awe-sport.png", altText: "AWE Sport" },
    { id: 27, imageUrl: "/clients/logo-borgo-medievale.png", altText: "Borgo Medievale Torino" },
    { id: 28, imageUrl: "/clients/logo-palazzo-reale-genova.png", altText: "Museo Palazzo Reale di Genova" },
    
    { id: 30, imageUrl: "/clients/logo-museo-civico-ivrea.png", altText: "Museo Civico Pier Alessandro Garda di Ivrea" },
    { id: 31, imageUrl: "/clients/logo-accorsi-ometto.png", altText: "Fondazione Accorsi-Ometto" },
    { id: 32, imageUrl: "/clients/logo-wine-museum.png", altText: "Wine Museum (Castello di Barolo)" },
    { id: 33, imageUrl: "/clients/logo-roveri.png", altText: "Royal Park Roveri" },
    { id: 34, imageUrl: "/clients/logo-direzione-regionale-musei.png", altText: "Direzione Regionale Musei Piemonte" },
    { id: 35, imageUrl: "/clients/logo-venaria.png", altText: "La Venaria Reale" },
    { id: 36, imageUrl: "/clients/logo-consulta-torino.png", altText: "Consulta Valorizzazione Beni Artistici e Culturali di Torino" },
    { id: 37, imageUrl: "/clients/logo-martini.png", altText: "MART" },
    
    
    { id: 39, imageUrl: "/clients/logo-rbm.png", altText: "Relevant Business Matters (RBM)" },
    { id: 40, imageUrl: "/clients/logo-joy.png", altText: "Fondazione Joy Gioielli" },
    { id: 41, imageUrl: "/clients/logo-museo-paesaggio-sonoro.png", altText: "Museo del Paesaggio Sonoro" },
    { id: 42, imageUrl: "/clients/logo-musli.png", altText: "MUSLI (Museo della Scuola e del Libro per l'Infanzia)" },
    { id: 43, imageUrl: "/clients/Logo-Siel.png", altText: "SIEL" },
    { id: 44, imageUrl: "/clients/logo-biblioRoma.png", altText: "BIBLIOTECA CENTRALE DI ROMA" },
    { id: 45, imageUrl: "/clients/logo-centroRicercheFiat.png", altText: "CENTRO RICERCHE FIAT" },
    { id: 46, imageUrl: "/clients/logo-compagniaCaraibi.png", altText: "COMPAGNIA DEI CARAIBI" },
    { id: 47, imageUrl: "/clients/logo-compassgroup.png", altText: "COMPASS GROUP" },
    { id: 48, imageUrl: "/clients/logo-conrotto.png", altText: "conrotto/progetti" },
    { id: 49, imageUrl: "/clients/logo-deltatre.png", altText: "deltatre" },
    { id: 50, imageUrl: "/clients/logo-engineering.png", altText: "engineering" },
    { id: 51, imageUrl: "/clients/logo-girarrostiSantaRita.png", altText: "girarrosti santa rita" },
    { id: 52, imageUrl: "/clients/logo-hco.png", altText: "HCO" },
    { id: 53, imageUrl: "/clients/logo-korus.png", altText: "KORUS" },
    { id: 54, imageUrl: "/clients/logo-INDRA-MINSAIT.png", altText: "INDRA-MINSAIT" },
    
    { id: 56, imageUrl: "/clients/logo-manital.png", altText: "MANITAL" },
    { id: 57, imageUrl: "/clients/logo-museo-torino.png", altText: "Museo Torino" },
    { id: 58, imageUrl: "/clients/logo-mibac2.png", altText: "ministero per i beni e le attività culturali" },
    
    { id: 60, imageUrl: "/clients/logo-museoAlfa.png", altText: "museo storico alfa romeo" },
    { id: 61, imageUrl: "/clients/logo-oma.png", altText: "OMA" },
    { id: 62, imageUrl: "/clients/logo-opera-barolo.png", altText: "opera di barolo" },
    { id: 63, imageUrl: "/clients/logo-rivoli.png", altText: "Castello di Rivoli" },
    { id: 64, imageUrl: "/clients/logo-primarmi.png", altText: "PRIMARMI" },
    { id: 65, imageUrl: "/clients/logo-relevant.png", altText: "RELEVANT" },
    { id: 66, imageUrl: "/clients/logo-reply.png", altText: "reply" },
    { id: 67, imageUrl: "/clients/logo-san-paolo.png", altText: "san paolo editoriale" },
    { id: 68, imageUrl: "/clients/logo-sisport.png", altText: "SI SPORT" },
    { id: 69, imageUrl: "/clients/logo-techdata.png", altText: "TECHDATA" },
    { id: 70, imageUrl: "/clients/logo-xiu.png", altText: "X!U" },
    { id: 71, imageUrl: "/clients/logo-fondazionesanpaolo.png", altText: "Fondazione Compagnia di San Paolo" },
    { id: 72, imageUrl: "/clients/logo-fondazione-luigi-einaudi.png", altText: "Fondazione luigi einaudi" },
    
    { id: 74, imageUrl: "/clients/logo-ims.png", altText: "IMS" },
    { id: 75, imageUrl: "/clients/logo-kpmg.png", altText: "KPMG" },
    { id: 76, imageUrl: "/projects/logo-diocesano.png", altText: "Diocesi di Torino" }
    
];

const ClientGrid: React.FC = () => {
    return (
        <div className="flex flex-col relative space-y-10 pt-20">
            <div className="md:flex justify-center w-full">
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-7 2xl:gap-[6rem] text-white h-full w-auto lg:mx-10 animate-fadeIn delay-9 ">
                    <div className="col-span-full flex flex-col justify-center items-center pb-16">
                        <h1 className="text-4xl md:text-6xl uppercase">{t("OUR CLIENTS")}</h1>
                        <p className="font-mono text-center py-4">{t("BUILDING LASTING RELATIONSHIPS BASED ON TRUST AND DEDICATION")}</p>
                    </div>
                    {clientLogos.map((logo) => (
                        <div
                            key={logo.id}
                            className="flex flex-col items-center lg:my-4 my-[3rem] w-full"
                            /*data-aos="fade-zoom-in"
                            data-aos-offset="50"
                            data-aos-delay="50"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="300"
                            data-aos-once="true"*/
                        >
                            <div className="flex items-center justify-center w-[17rem] sm:w-auto 2xl:w-[17rem] xl:w-[16rem] ">
                                <img
                                    className="object-contain h-[3rem] sm:h-[10rem] 2xl:h-[5rem] px-[3rem] w-full hover:scale-110 "
                                    src={logo.imageUrl}
                                    alt={logo.altText}
                                    style={{ filter: "brightness(0) invert(1)" }} // Makes the logo white
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClientGrid;
