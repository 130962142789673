import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import ExhibitSlider from "../common/ExhibitSlider";
import SignageSlider from "../common/ExhibitSlider";
import PartnerGrid from "../common/PartnerGrid";
import SectionsHeader from "../components/SectionsHeader";
import {useTranslation} from 'react-i18next'
import Helmetribes from "./helmetMetatags";
export default function Networks() {
  const {t}=useTranslation();
  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
        <Helmetribes title={t("NETWORKS")} description={t("Reliable Connectivity for Seamless Operations")}url="https://www.ribesdigilab.it/services/networks"/>
      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1  ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-70 " >
            {/*<img src="/projects/MARTINI LEDWALL/CASA_MARTINI_0.jpg" alt="sara" className="object-cover w-full h-full" />*/}
            <img src="/projects/NETWORKS/CABLES.jpg" alt="sara" className="object-cover w-full h-full " />

          </div>

        <div className="flex flex-col">
          <SectionsHeader title={t("NETWORKS")} subtitle={t("Reliable Connectivity for Seamless Operations")}/>

          <div className="absolute inset-0 h-screen w-auto">
            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/services/networks/#infos"  description1={t("what we do")} to2="/partners" description2={t("tech partners")} /></div>
            <div id="infos"/>
          </div>
        </div>
      </div>

        <div className="mx-12 mb-4 mt-4 h-full flex flex-col bg-opacity-50 z-40 mt-40 pt-20">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                
                <ImageTextHome
                    text={t("WHAT WE DO")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                description={t("We design, implement, and manage network infrastructures that ensure reliable, high-speed connectivity for businesses, museums, exhibitions, and public spaces. Our custom network solutions are tailored to meet the specific requirements of your environment, ensuring efficient communication, data transfer, and system integration. From wired to wireless solutions, we provide robust networks that keep your operations running smoothly.")} textAlign="left"
                    />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text={t("HOW WE DO IT")}
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description={t("We begin by assessing your space and identifying the specific needs of your network infrastructure, such as the number of devices, bandwidth requirements, and security protocols. Our team then designs a network layout that optimizes performance and scalability. We handle everything from installing network cabling to configuring routers, switches, and access points. Our solutions are built to support high traffic and multiple devices without compromising on speed or reliability. Once the network is live, we offer continuous monitoring and maintenance to ensure everything operates seamlessly.")} textAlign="right"
                    reverse={true}
                />
              </div>

            </div>
          </div>
        </div>



        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/projects/NETWORKS/cables_1.jpg"
              className="w-auto md:w-5/12  h-auto "
                 alt="sara"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24  font-bold  "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >



            </div>
          </div>
          
          <div className="py-20 " ></div>
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mb-12 text-5xl leading-120 tracking-tighter text-center uppercase text-white " >
              {t("SERVICES WE PROVIDE")}

          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Wired Network Infrastructure")}
                description={t("We install and configure high-speed Ethernet cabling (Cat6, Cat6a, Cat7, etc.) to ensure reliable, high-capacity data transfer in large and small spaces. Our wired network solutions provide stable connections for devices, servers, and other network components, delivering fast and secure communication throughout your environment.")}
            /></div>

          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Wi-Fi Networks")}
                description={t("We set up wireless networks with access points that ensure strong, uninterrupted Wi-Fi coverage throughout your space. Ideal for high-traffic areas such as museums, exhibition halls, or large corporate offices. Our Wi-Fi networks are designed to support multiple devices and users simultaneously, providing seamless connectivity for all your digital needs.")}
            />
          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title={t("Fiber Optic Networks")}
                description={t("Fiber optic solutions provide ultra-fast data transfer speeds and higher bandwidth, perfect for data-heavy environments and long-distance connectivity needs. Our fiber optic networks are designed to deliver reliable, high-performance connections for businesses, museums, and public spaces, ensuring optimal communication and data transfer capabilities.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Network Security Solutions")}
                description={t("We offer advanced security protocols including firewalls, VPNs, and encryption to protect your network from unauthorized access and cyber threats. Our security solutions are designed to safeguard your data, devices, and communications, ensuring that your network remains secure and protected at all times.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Private Networks (LAN/WAN)")}
                description={t("We design and deploy both local area networks (LAN) and wide area networks (WAN) to ensure secure communication across your organization, whether in a single building or across multiple locations. Our private networks provide dedicated connections for internal communication, data sharing, and system integration, enabling seamless collaboration and information exchange.")}
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Cloud-Based Networking")}
                description={t("We provide cloud-managed networking solutions that allow for easier maintenance and scalability. Cloud-based networks provide centralized control and remote management for multi-site operations. Our cloud networking solutions offer flexibility, efficiency, and cost savings, making them an ideal choice for businesses looking to streamline their network infrastructure.")}
            />
          </div> <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Network Monitoring and Maintenance")}
                description={t("Our team offers ongoing monitoring services to detect issues before they affect performance, along with regular maintenance to keep your network running smoothly. We provide proactive support, troubleshooting, and updates to ensure that your network infrastructure remains secure, reliable, and optimized for peak performance.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Virtual LAN (VLAN) Configuration")}
                description={t("VLANs help separate network traffic into different segments, improving security and performance. We set up and configure VLANs to meet the specific needs of your operations. VLANs can be used to isolate sensitive data, optimize network traffic, and enhance network security, providing a more efficient and secure network environment.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("VoIP (Voice over IP) Solutions")}
                description={t("We offer VoIP systems that allow businesses to make voice calls over the internet, reducing telecommunication costs and integrating voice services with your existing network. VoIP solutions provide advanced features such as call forwarding, voicemail, and video conferencing, enabling efficient communication and collaboration across your organization.")}
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title={t("Network Infrastructure for IoT")}
                description={t("As more devices connect to the Internet of Things (IoT), we offer specialized network configurations to handle smart sensors, devices, and systems that enhance automation and data collection. Our IoT network solutions are designed to support the growing number of connected devices, ensuring seamless communication and data transfer for your IoT applications.")}
            />
          </div>

        </div>




        {/*ANIMATED BLOCKS*/}

        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={1} end={26} unit={t("y")} />
            <Counter type="letter" start="" end="TECH" />
            <Counter type="watt" start={100} end={1000} unit="" />
          </div>
        </div>

        

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
              {t("DO YOU HAVE NEED A CUSTOM SOLUTION?")}
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">
              <div className="">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/stylus_note (1).svg"
                    title={t("we can design that")}
                    description={t("We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently")}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/desktop_windows (1).svg"
                    title={t("we can install that")}
                    description={t("We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization.")}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/volunteer_activism.svg"
                  title={t("we can assist you overtime")}
                  description={t("We build advanced, custom network solutions and we take care of maintenance overtime.")}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>



        {/* VIDEO Section */}
        {/*<div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">


        <div>
          <div>

            <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
            >
              <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>*/}

        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 text-center uppercase font-medium text-white ">
              {t("LET'S TALK ABOUT IT!")}
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className="font-mono w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
              {t("CONTACT US")}
              </button>
            </div>
          </Link>
        </div>

      </div>
      {/* {isPopupOpen && fileType && (
          <EmailPopup fileType={fileType} onClose={handleClosePopup} />
      )}*/}
      {isPopupOpen && (
          <OverlayForm
              formUrl="https://webforms.pipedrive.com/f/6UR9Re8nR5VKrsxbJHNTBDLcsXv6xjIStMZYDU4iiOvEJdEo7cZwg4M1uAm9NAG7WX"
              onClose={handleClosePopup}
          />
      )}
    </div>
  );
}
